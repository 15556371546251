<template>
  <div class="body">
    <div style="margin-bottom: 27px">
      <list-filter v-model="filterForm" :uploadFilter="ok" />
    </div>
    <div class="list-content">
      <el-table :data="tbody" class="thead-light" stripe style="width: 100%" v-loading="loading"
        @sort-change="sortChange">
        <template v-for="(th, index) in thead">
          <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label"
            :minWidth="th.minWidth || ''" :sortable="th.sortable" :key="index">
            <template slot-scope="scope">
              <!-- 所属组织Tooltip-->
              <span v-if="th.prop === 'brands'">{{ scope.row[th.prop].join("、") | placeholder }}</span>
              <!-- 活动状态 -->
              <p v-else-if="th.prop === 'activity_status_text_three'">
                <span :class="[
                      'status-dot',
                      scope.row.activity_status_text_two === 3 ? 'green' : scope.row.activity_status_text_two === 1 || scope.row.activity_status_text_two === 4?'grey':'yellow',
                    ]"></span>
                {{ scope.row[th.prop] }}
              </p>
              <!-- 普通 -->
              <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage" />
  </div>
</template>

<script>
import listFilter from "../../components/Member/MyActivityFilter";
import Pagination from "@/base/components/Default/Pagination";
import { myActivityList } from "../../api/member/member-detail";
export default {
  props: {
    data: {
      type: String,
    },
  },
  data() {
    return {
      loading: false, //加载
      thead: [
        { label: "ID", prop: "id", minWidth: 70 },
        { label: "活动名称", prop: "name", minWidth: 150 },
        { label: "活动类型", prop: "activity_type_text", minWidth: 100 },
        { label: "报名人数", prop: "activity_users_count", minWidth: 100 },
        { label: "报名类型", prop: "signup_type_text", minWidth: 100 },
        { label: "所属组织", prop: "brands", minWidth: 150 },
        { label: "创建者", prop: "creator_name", minWidth: 100 },
        { label: "参与类型", prop: "join_type_text", minWidth: 100 },
        {
          label: "活动状态",
          prop: "activity_status_text_three",
          minWidth: 120,
        },
        {
          label: "成立时间",
          prop: "activity_start_time",
          minWidth: 200,
          sortable: true,
        },
      ],
      //筛选对象
      filterForm: {
        id: this.data?this.$deCode(this.data).id:this.$route.params.user_id,
        activity_type: -1, //内容类型
        signup_type: -1, //内容分类
        activity_status: -1, //回复状态
        join_type: -1, //参与类型
        keyword: "", //活动名称
        page_size: 15, //每页数据数
      },
      tbody: [],
      pageData: {},
    };
  },
  methods: {
    // 获取列表数据
    getMyActivityList(pageData) {
      this.loading = true;
      myActivityList(pageData)
        .then((res) => {
          const { data } = res;
          this.tbody = data.data;
          this.pageData = data;
          this.exchangeParentList(data.data);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 所属组织格式处理
    exchangeParentList(data) {
      data.forEach((element) => {
        let brandList = [];
        if (element.brands.length) {
          element.brands.forEach((item) => {
            brandList.push(item.name);
          });
        }
        element.brands = brandList;
      });
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getMyActivityList(this.filterForm);
    },
    // 筛选回调
    ok(e) {
      const pageData = {};
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.getMyActivityList({ ...this.filterForm, ...pageData });
    },
    // 刷新列表
    reflashList() {
      this.getMyActivityList({
        page: this.pageData.current_page,
        ...this.filterForm,
      });
    },
    // 分页查询
    changPage(e) {
      console.log(e);
      this.filterForm.page_size = e.page_size;
      const pageData = { ...this.filterForm, ...e };
      this.getMyActivityList(pageData);
    },
  },
  created() {
    this.getMyActivityList(this.filterForm); //获取列表数据
  },
  components: { listFilter, Pagination },
};
</script>

<style lang="scss" scoped>
.body {
  min-height: calc(100vh - 270px);
  .tool-tip {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
</style>
