<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :toggleCollapseEnable="false">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="85px" size="medium"
      class="filter-from">
      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="活动名称：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入关键字"></el-input>
        </el-form-item>
        <el-form-item label="活动类型：" prop="activity_type">
          <el-select v-model="activity_type" placeholder="请选择">
            <el-option v-for="item in activityTypeOptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报名类型：" prop="signup_type">
          <el-select v-model="signup_type" placeholder="请选择">
            <el-option v-for="item in signUpTypeOptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="活动状态：" prop="activity_status">
          <el-select v-model="activity_status" placeholder="请选择">
            <el-option v-for="item in activityStautsOptions" :key="item.id" :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="参与类型：" prop="join_type">
          <el-select v-model="join_type" placeholder="请选择">
            <el-option v-for="item in joinTypeOptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
export default {
  model: {
    prop: "filterForm",
    event: "updateFilter",
  },
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
  },
  data() {
    return {
      rules: {},
      activityTypeOptions: [
        { name: "线上", id: 0 },
        { name: "线下", id: 1 },
      ],
      signUpTypeOptions: [
        { name: "免费报名", id: 0 },
        { name: "保证金报名", id: 1 },
        { name: "付费报名", id: 2 },
      ],
      activityStautsOptions: [
        { name: "已取消", id: 0 },
        { name: "报名未开始", id: 1 },
        { name: "报名中", id: 2 },
        { name: "报名结束，活动未开始", id: 3 },
        { name: "进行中", id: 4 },
        { name: "已结束", id: 5 },
      ],
      joinTypeOptions: [
        { name: "报名参与", id: 0 },
        { name: "发起活动", id: 1 },
      ],
    };
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  created() {},
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {
        this.$emit("updateFilter", val);
      },
    },
    activity_type: {
      get() {
        return this.form.activity_type == -1 ? "" : this.form.activity_type;
      },
      set(val) {
        this.form.activity_type = val;
      },
    },
    signup_type: {
      get() {
        return this.form.signup_type == -1 ? "" : this.form.signup_type;
      },
      set(val) {
        this.form.signup_type = val;
      },
    },
    activity_status: {
      get() {
        return this.form.activity_status == -1 ? "" : this.form.activity_status;
      },
      set(val) {
        this.form.activity_status = val;
      },
    },
    join_type: {
      get() {
        return this.form.join_type == -1 ? "" : this.form.join_type;
      },
      set(val) {
        this.form.join_type = val;
      },
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>